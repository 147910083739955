import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d95f2e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page_header" }
const _hoisted_2 = { class: "d-flex align-items-center justify-content-end" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "formContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_overview = _resolveComponent("overview")!
  const _component_scope_1_resume = _resolveComponent("scope-1-resume")!
  const _component_scope_2_resume = _resolveComponent("scope-2-resume")!
  const _component_scope_3_resume = _resolveComponent("scope-3-resume")!
  const _component_ghg_inventory = _resolveComponent("ghg-inventory")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        type: 'h1',
        localizedKey: "Dashboard",
        text: "Dashboard",
        class: "mb-0"
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn me-2", _ctx.activeSection == 'Overview' ? 'btn-tertiary': 'btn-secondary withBorder']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeSection = 'Overview'))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Overview",
            text: "Overview"
          })
        ], 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scopesList, (scope) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(["btn me-2", _ctx.activeSection == scope ? 'btn-tertiary': 'btn-secondary withBorder']),
            key: scope,
            onClick: ($event: any) => (_ctx.activeSection = scope)
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: scope,
              text: scope
            }, null, 8, ["localizedKey", "text"])
          ], 10, _hoisted_3))
        }), 128)),
        _createElementVNode("button", {
          class: _normalizeClass(["btn me-2", _ctx.activeSection == 'GHG Inventory' ? 'btn-tertiary': 'btn-secondary withBorder']),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeSection = 'GHG Inventory'))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "GHG Inventory",
            text: "GHG Inventory"
          })
        ], 2),
        (_ctx.availableYears.length > 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDownloadReportModal()))
            }, [
              _createVNode(_component_localized_text, {
                localizedKey: "Download PDF report",
                text: "Download PDF report"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.activeSection == 'Overview')
        ? (_openBlock(), _createBlock(_component_overview, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.activeSection == _ctx.viewModel.Scopes.Scope1)
        ? (_openBlock(), _createBlock(_component_scope_1_resume, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.activeSection == _ctx.viewModel.Scopes.Scope2)
        ? (_openBlock(), _createBlock(_component_scope_2_resume, { key: 2 }))
        : _createCommentVNode("", true),
      (_ctx.activeSection == _ctx.viewModel.Scopes.Scope3)
        ? (_openBlock(), _createBlock(_component_scope_3_resume, { key: 3 }))
        : _createCommentVNode("", true),
      (_ctx.activeSection == 'GHG Inventory')
        ? (_openBlock(), _createBlock(_component_ghg_inventory, { key: 4 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}