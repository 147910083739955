
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: { 
    },
})
export default class GHGInventory extends Vue {

    ghgInventoryData: OM.GHGInventoryVM = new OM.GHGInventoryVM();
    availableYears: string[] = [];
    selectedYear: string = "";

    tonnesVisualization: boolean = false;
    loaded: boolean = false;

    created() {
        this.init(null);
    }

    init(selectedYearNumber: number | null) {
        this.availableYears = [];
        this.availableYears.push("-- All years")

        ActivityDataRecorderClient.getGHGInventory(selectedYearNumber)
        .then(x => {
            this.ghgInventoryData = x;

            this.selectedYear = this.ghgInventoryData.selectedYear ? this.ghgInventoryData.selectedYear.toString() : "-- All years";
            this.ghgInventoryData.availableYears.forEach(element => {
                this.availableYears.push(element.toString());
            });

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

    get years() {
        const yearsSet = new Set<string>();
        this.ghgInventoryData.tableValues.forEach((item) => {
            item.valuesPerYear.forEach((value) => {
                yearsSet.add(value.key);
            });
        });
        return Array.from(yearsSet).sort(); // Ordina gli anni
    }

    // Funzione per trovare il valore corrispondente all'anno
    getValueForYear(values: { key: string; value: number }[], year: string) {
        const found = values.find((item) => item.key === year);
        return found ? found.value : 0;
    }

    updateTimePeriod() {
        if(this.selectedYear != "-- All years")
            this.init(parseInt(this.selectedYear));
        else
            this.init(null);
    }

}
