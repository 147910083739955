
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient } from '@/services/Services';
import BarChart from '@/components/barChart.vue';
import * as OM from '@/Model';

@Options({
    components: { 
        BarChart
    },
})
export default class Scope3Resume extends Vue {

    scope2ResumeData: OM.Scope23ResumeVM = new OM.Scope23ResumeVM();
    availableYears: string[] = [];
    selectedYear: string = "";

    tonnesVisualization: boolean = false;
    barChartKey: number = 0;
    loaded: boolean = false;

    created() {
        this.init(null);
    }

    init(selectedYearNumber: number | null) {
        this.availableYears = [];
        this.availableYears.push("-- All years")

        ActivityDataRecorderClient.getScope3Resume(selectedYearNumber)
        .then(x => {
            this.scope2ResumeData = x;

            this.selectedYear = this.scope2ResumeData.selectedYear ? this.scope2ResumeData.selectedYear.toString() : "-- All years";
            this.scope2ResumeData.availableYears.forEach(element => {
                this.availableYears.push(element.toString());
            });

            this.barChartKey = Math.random();

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

    get years() {
        const yearsSet = new Set<string>();
        this.scope2ResumeData.tableValues.forEach((item) => {
            item.valuesPerYear.forEach((value) => {
                yearsSet.add(value.key);
            });
        });
        return Array.from(yearsSet).sort(); // Ordina gli anni
    }

    // Funzione per trovare il valore corrispondente all'anno
    getValueForYear(values: { key: string; value: number }[], year: string) {
        const found = values.find((item) => item.key === year);
        return found ? found.value : 0;
    }

    get categories() {
        const categoriesSet = new Set<string>();
        this.scope2ResumeData.premisesPerCategories.forEach((item) => {
            item.categoriesEmissions.forEach((value) => {
                categoriesSet.add(value.key);
            });
        });
        return Array.from(categoriesSet).sort();
    }
    getValueForCategory(values: { key: string; value: number }[], year: string) {
        const found = values.find((item) => item.key === year);
        return found ? found.value : 0;
    }

    get chartDatas() {
        this.barChartKey = Math.random();

        if(!this.tonnesVisualization)
            return this.scope2ResumeData.chartDatas;

        var ris: { [key: string]: OM.KeyValuePairOfStringAndDouble[]; } = {};
        var keys = Object.keys(this.scope2ResumeData.chartDatas);
        keys.forEach(key => {
            var valuesList: OM.KeyValuePairOfStringAndDouble[] = [];
            this.scope2ResumeData.chartDatas[key].forEach(element => {
                var tonnesElement = {...element};
                tonnesElement.value = tonnesElement.value / 1000;
                valuesList.push(tonnesElement);
            });

            ris[key] = valuesList;
        });

        return ris;
    }

    updateTimePeriod() {
        if(this.selectedYear != "-- All years")
            this.init(parseInt(this.selectedYear));
        else
            this.init(null);
    }

}
