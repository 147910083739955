
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient } from '@/services/Services';
import Overview from '../components/overview.vue';
import Scope1Resume from '../components/scope1resume.vue';
import Scope2Resume from '../components/scope2resume.vue';
import Scope3Resume from '../components/scope3resume.vue';
import GhgInventory from '../components/ghgInventory.vue';
import DownloadReportModal from '../modals/downloadReportModal.vue';
import * as VM from '@/viewModel';

@Options({
    components: { 
        Overview,
        Scope1Resume,
        Scope2Resume,
        Scope3Resume,
        GhgInventory
    },
})
export default class Dashboard extends Vue {

    viewModel = VM;
    activeSection: string = "Overview";

    scopesList: string[] = [];
    availableYears: number[] = [];

    created() {
        this.scopesList = Object.values(VM.Scopes);

        ActivityDataRecorderClient.getAvailableYears()
        .then(x => {
            // this.availableYears.push("-- All years")
            x.forEach(element => {
                this.availableYears.push(element);
            });
        })
    }

    openDownloadReportModal() {
        this.$opModal.show(DownloadReportModal, {
            availableYears: this.availableYears
        })
    }

}
