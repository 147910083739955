
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient } from '@/services/Services';
import Chart from '@/views/calculator/components/chart.vue';
import LineChart from '@/components/lineChart.vue';
import BarChart from '@/components/barChart.vue';
import * as OM from '@/Model';

@Options({
    components: { 
        Chart,
        LineChart,
        BarChart
    },
})
export default class Overview extends Vue {

    overviewModel: OM.OverviewVM = new OM.OverviewVM();
    availableYears: string[] = [];
    selectedYear: string = "";

    tonnesVisualization: boolean = false;
    pieChartKey: number = 0;
    lineChartKey: number = 0;
    barChartKey: number = 0;
    loaded: boolean = false;

    created() {
        this.init(null);
    }

    init(selectedYearNumber: number | null) {
        this.availableYears = [];
        this.availableYears.push("-- All years")

        ActivityDataRecorderClient.getOverviewData(selectedYearNumber)
        .then(x => {
            this.overviewModel = x;

            this.selectedYear = this.overviewModel.selectedYear ? this.overviewModel.selectedYear.toString() : "-- All years";
            this.overviewModel.availableYears.forEach(element => {
                this.availableYears.push(element.toString());
            });

            this.pieChartKey = Math.random();
            this.lineChartKey = Math.random();
            this.barChartKey = Math.random();

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

    get scopePercentagePieChart() {
        var ris = new OM.SummaryChartVM();

        var scope1 = new OM.SummaryChartSectionVM();
        scope1.sectionName = "Scope-01";
        scope1.percentage = (this.overviewModel.totalEmissionsScope1 * 100) / this.overviewModel.totalEmissions;
        scope1.colorHex = "#1abc9c";

        var scope2 = new OM.SummaryChartSectionVM();
        scope2.sectionName = "Scope-02";
        scope2.percentage = (this.overviewModel.totalEmissionsScope2 * 100) / this.overviewModel.totalEmissions;
        scope2.colorHex = "#3498db";

        var scope3 = new OM.SummaryChartSectionVM();
        scope3.sectionName = "Scope-03";
        scope3.percentage = (this.overviewModel.totalEmissionsScope3 * 100) / this.overviewModel.totalEmissions;
        scope3.colorHex = "#9b59b6";

        ris.chartSections.push(scope1);
        ris.chartSections.push(scope2);
        ris.chartSections.push(scope3);

        return ris;
    }

    get emissionsByMonth() {
        this.lineChartKey = Math.random();
        
        if(!this.tonnesVisualization)
            return this.overviewModel.emissionsByMonth;

        var ris: { [key: string]: number; } = {};
        var keys = Object.keys(this.overviewModel.emissionsByMonth);
        keys.forEach(element => {
            ris[element] = this.overviewModel.emissionsByMonth[element] / 1000;
        });

        return ris;
    }

    get premisesPercentagePieChart() {
        var ris = new OM.SummaryChartVM();
        const sumsByKey = {};

        Object.entries(this.overviewModel.emissionsByPremises).forEach(([key, items]) => {
            sumsByKey[key] = items.reduce((sum, item) => sum + item.value, 0);
        });

        const totalSum = Object.values(sumsByKey).reduce((acc, value) => parseFloat(acc.toString()) + parseFloat(value.toString()), 0);
        var premises = Object.keys(sumsByKey);

        premises.forEach(element => {
            var prem = new OM.SummaryChartSectionVM();
            prem.sectionName = element;
            prem.colorHex = this.$utils.getHexColor();
            prem.percentage = (sumsByKey[element] * 100) / parseFloat(totalSum.toString());
            ris.chartSections.push(prem);
        });

        return ris;
    }

    get emissionsByPremises() {
        this.barChartKey = Math.random();

        if(!this.tonnesVisualization)
            return this.overviewModel.emissionsByPremises;

        var ris: { [key: string]: OM.KeyValuePairOfStringAndDouble[]; } = {};
        var keys = Object.keys(this.overviewModel.emissionsByPremises);
        keys.forEach(key => {
            var valuesList: OM.KeyValuePairOfStringAndDouble[] = [];
            this.overviewModel.emissionsByPremises[key].forEach(element => {
                var tonnesElement = {...element};
                tonnesElement.value = tonnesElement.value / 1000;
                valuesList.push(tonnesElement);
            });

            ris[key] = valuesList;
        });

        return ris;
    }

    updateTimePeriod() {
        if(this.selectedYear != "-- All years")
            this.init(parseInt(this.selectedYear));
        else
            this.init(null);
    }

}
