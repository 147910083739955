
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient } from '@/services/Services';
import Chart from '@/views/calculator/components/chart.vue';
import LineChart from '@/components/lineChart.vue';
import BarChart from '@/components/barChart.vue';
import * as OM from '@/Model';

@Options({
    components: { 
        Chart,
        LineChart,
        BarChart
    },
})
export default class Scope1Resume extends Vue {

    scope1ResumeData: OM.Scope1ResumeVM = new OM.Scope1ResumeVM();
    availableYears: string[] = [];
    selectedYear: string = "";

    tonnesVisualization: boolean = false;
    lineChartKey: number = 0;
    pieChartKey: number = 0;
    loaded: boolean = false;

    created() {
        this.init(null);
    }

    init(selectedYearNumber: number | null) {
        this.availableYears = [];
        this.availableYears.push("-- All years")

        ActivityDataRecorderClient.getScope1Resume(selectedYearNumber)
        .then(x => {
            this.scope1ResumeData = x;

            this.selectedYear = this.scope1ResumeData.selectedYear ? this.scope1ResumeData.selectedYear.toString() : "-- All years";
            this.scope1ResumeData.availableYears.forEach(element => {
                this.availableYears.push(element.toString());
            });

            this.lineChartKey = Math.random();
            this.pieChartKey = Math.random();

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

    get emissionsByMonth() {
        this.lineChartKey = Math.random();
        
        if(!this.tonnesVisualization)
            return this.scope1ResumeData.emissionsByMonth;

        var ris: { [key: string]: number; } = {};
        var keys = Object.keys(this.scope1ResumeData.emissionsByMonth);
        keys.forEach(element => {
            ris[element] = this.scope1ResumeData.emissionsByMonth[element] / 1000;
        });

        return ris;
    }

    get premisesPercentagePieChart() {
        var ris = new OM.SummaryChartVM();

        const totalSum = this.scope1ResumeData.premisesTotals.reduce((acc, value) => acc + value.value, 0);

        this.scope1ResumeData.premisesTotals.forEach(element => {
            var prem = new OM.SummaryChartSectionVM();
            prem.sectionName = element.key;
            prem.colorHex = this.$utils.getHexColor();
            prem.percentage = (element.value * 100) / totalSum;
            ris.chartSections.push(prem);
        });

        return ris;
    }

    updateTimePeriod() {
        if(this.selectedYear != "-- All years")
            this.init(parseInt(this.selectedYear));
        else
            this.init(null);
    }

}
