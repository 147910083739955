
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import { baseUrl } from '@/config';
import * as OM from '@/Model';

@Options({})
export default class DownloadReportModal extends Vue {

    @Prop({
        default: () => []
    }) availableYears: number[];

    @Prop() callback: any;

    // selectedYearString: string = "-- All years";
    selectedYears: number[] = [];
    selectedUnit: string = "kg";
    units: string[] = [];

    tonnesVisualization: boolean = false;
    loggedUser: OM.LoggedUserVM = StorageServices.getLoggedUser();

    created() {
        this.units = ["kg", "Tonnes"];
        this.availableYears.forEach(element => {
            this.selectedYears.push(element);
        });
    }

    toggleYear(year: number) {
        var pos = this.selectedYears.indexOf(year);
        if(pos == -1)
            this.selectedYears.push(year);
        else
            this.selectedYears.splice(pos, 1);
    }

    downloadReport() {
        // var selectedYear = -1;
        // if(this.selectedYearString != '-- All years')
        //     selectedYear = parseInt(this.selectedYearString);

        this.tonnesVisualization = this.selectedUnit == "kg" ? false : true;

        this.$utils.downloadPdfReport(baseUrl + "api/ActivityDataRecorder/DownloadPDFReport", this.loggedUser.company.identifier, this.tonnesVisualization, this.selectedYears);
    }
    
}
